
.swal2-container{
    font-family: Roboto, serif;
    z-index: 1000000;
}

/* Dark Theme for SweetAlert2 */
.swal2-popup {
    background-color: #2c2f33;
    color: #adbac7;
}

.swal2-title {
    color: #e2e4e6;
}

.swal2-content {
    color: #e2e4e6;
}

.swal2-styled.swal2-confirm {
    background-color: #009fe8;
}

.swal2-styled.swal2-cancel {
    background-color: #f8485e;
}

.swal2-icon {
    color: #e2e4e6;
}

.swal2-close {
    color: #e2e4e6;
}
