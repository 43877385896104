.scrollLink {
    width: 100%;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInSlideIn {
    animation: slideDown 0.5s ease-out forwards;
}
