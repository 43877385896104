/* custom-scrollbar.css */
/* For webkit-based browsers */
::-webkit-scrollbar {
    width: 10px;
    border-radius: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    border-radius: 12px;
}

/* For Firefox */
scrollbar-width: thin;
scrollbar-color: #888 #f0f0f0;

body {
    font-family: 'Roboto', sans-serif;
}

.Toastify__toast-body {
    min-width: 280px !important;
    width: auto !important;
    max-width: 600px !important;
    z-index: 199999999 !important;
}

.Toastify__progress-bar {
    min-width: 280px !important;
    width: auto !important;
    max-width: 600px !important;
    z-index: 199999999 !important;
}

.Toastify__toast-container {
    min-width: 280px !important;
    width: auto !important;
    max-width: 600px !important;
    z-index: 199999999 !important;
}

.Toastify__toast {
    min-width: 280px !important;
    width: auto !important;
    max-width: 600px !important;
    z-index: 199999999 !important;
}
.Toastify__close-button:hover {
    background-color: transparent !important;
}
html {
    scroll-behavior: smooth;
}