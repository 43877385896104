.wrapperTextEditor {
  width: 100% !important;
  max-width: 100% !important;
  min-height: 350px !important;
}

.editor-container {
  padding-left: 25px !important;
  padding-right: 25px !important;
  border: 1px solid rgba(255, 255, 255, 0.23)!important;
  min-height: 350px !important;
  max-height: 350px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  font-family: Roboto, serif !important;
}
.editor-class {
  font-family: 'Roboto', sans-serif !important; /* Use !important to override any default styles */
}

.toolbar-container {
  border: 1px solid rgba(255, 255, 255, 0.23)!important;
  background-color: #1A2D3F !important;
}

.wysiwyg-blockType a {
  color: #444343 !important;
}

.wysiwyg-blockType a:hover {
  text-decoration: none !important;
  color: #444343 !important;
}

.wysiwyg-blockType {
  border: none !important;
  box-shadow: none !important;
}

.wysiwyg-icon .rdw-option-wrapper .rdw-list-wrapper .rdw-text-align-wrapper{
  border:  1px solid rgba(255, 255, 255, 0.23) !important;
  box-shadow: none !important;
}
.wysiwyg-icon .rdw-option-active {
  background-color: #FFFFFF !important;
  border:  1px solid #EE814D !important;
  box-shadow: 2px 2px 0px #EE814D inset !important;
}
.rdw-dropdown-wrapper {
  color: black !important;
  font-family: Roboto, serif !important;
}

.rdw-link-modal{
  background-color: #1A2D3F !important;
  box-shadow: 3px 3px 5px #1A2D3F !important;
  border:  1px solid rgba(255, 255, 255, 0.23) !important;
  font-family: Roboto, serif !important;
}

.rdw-dropdown-optionwrapper{
  background-color: #1A2D3F !important;
  border:  1px solid rgba(255, 255, 255, 0.23) !important;
  font-family: Roboto, serif !important;
  color: #FFFFFF !important;
}
.rdw-dropdownoption-active{
  background-color: #EE814D !important;
}
.rdw-dropdownoption-highlighted{
  background-color: rgba(238, 129, 77, 0.6) !important;
}